import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import mq from '../utils/mediaQuery';

const H1 = styled.h1`
  ${mq({
    width: '100%',
    fontSize: [12, 24, 24],
    marginTop: ['4px', '8px', '8px'],
    marginBottom: ['8px', '16px', '16px'],
  })};
`;
const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <H1>Page Not Found</H1>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
